import { memo, useEffect, useState } from 'react';
import { CitySelect, CountrySelect, StateSelect } from '@davzon/react-country-state-city';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Modal from 'react-bootstrap/Modal';
import VirtualizedAutocomplete from './Autocomplete';
import IconButton from '@mui/material/IconButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import FileUploader from './FileUploader';
import '@davzon/react-country-state-city/dist/react-country-state-city.css';

const Form = ({ show = false, title = "", isLoading = false, columns = [], selectedRow = {}, dropdowns = {}, rowFiles = [], rowType = null, uploadedFiles = [], setIsLoading = () => {}, setShowAlert = () => {}, setAlertMessage = () => {}, setAlertType = () => {}, setRowFiles = () => {}, setUploadedFiles = () => {}, setDeletedRowFileIds = () => {}, handleSubmitEditedRow = () => {}, handleSubmitAddedRow = () => {}, handleClose = () => {} }) => 
{
    /* LOCATION VARIABLES */
    const [country, setCountry] = useState({});
    const [state, setState] = useState({});

    /* FORM VARIABLES */
    const [formData, setFormData] = useState({});
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});

    useEffect(() => 
    {
        if (selectedRow) 
        {
            setFormData(selectedRow);
        }
    }, [selectedRow]);

    const handleValidation = (field, value) => 
    {
        const errors = {};

        if (field === "Email_Address") 
        {
            const emailPattern = new RegExp(/^(('[\w\s-]+')|([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

            if (value && !emailPattern.test(value)) 
            {
                errors[field] = "Invalid Email Format!";
            }
            else 
            {
                delete errors[field];
            }
        }
        else if (field === "Mobile_Number") 
        {
            if (value) 
            {
                const mobileNumberObj = parsePhoneNumberFromString(value, country.iso2);

                if (!mobileNumberObj?.isValid()) 
                {
                    errors[field] = "Invalid Mobile Number Format";
                }
            }
            else 
            {
                delete errors[field];
            }
        }

        if (Object.keys(errors).length === 0) 
        {
            setValidationErrors({});
        }
        else 
        {
            setValidationErrors((previousValidationErrors) => ({
                ...previousValidationErrors,
                ...errors
            }));
        }
    }

    const handleTextChange = (field, value) =>
    {
        if (!value)
        {
            if (field === "Country") 
            {
                setCountry(value);
            }
            else if (field === "State") 
            {
                setState(value);
            }
    
            setFormData((previousFormData) => ({
                ...previousFormData,
                [field]: value
            }));
        }
    }

    const handleChange = (field, value) => 
    {
        if (field === "Country") 
        {
            setCountry(value);
        }
        else if (field === "State") 
        {
            setState(value);
        }

        setFormData((previousFormData) => ({
            ...previousFormData,
            [field]: ["Country", "City", "State"].includes(field) ? value ? value.name : "" : typeof value === "string" ? value.trim() === "" ? null : value : value ? value : null
        }));

        handleValidation(field, value);
    }

    const handleSubmit = () => 
    {
        setIsFormSubmitted(true);

        if (Object.keys(validationErrors).length > 0) 
        {
            return;
        }
        else 
        {
            if (rowType === "Existing") 
            {
                handleSubmitEditedRow(formData);
            }
            else if (Object.keys(formData).length > 0) 
            {
                handleSubmitAddedRow(formData);
            }
            else 
            {
                handleClose();
            }
        }
    }

    return (
        <Modal className = "form-modal entities" show = {show} onHide = {handleClose} centered = {true}>
            <Backdrop
                sx = {{ color: "#fff", position: 'absolute', height: '100%', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open = {isLoading}
            >
                <CircularProgress color = "inherit" />
            </Backdrop>

            <Modal.Header closeButton = {true}>
                <Modal.Title>
                    {rowType === "Existing" ? `Edit ${title}` : `Add ${title}`}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {title === "Vendor" && (
                    <div className = "upload-files">
                        <FileUploader
                            isMultiple = {false}
                            files = {rowFiles}
                            uploadedFiles = {uploadedFiles}
                            setIsLoading = {setIsLoading}
                            setShowAlert = {setShowAlert}
                            setAlertMessage = {setAlertMessage}
                            setAlertType = {setAlertType}
                            setFiles = {setRowFiles}
                            setUploadedFiles = {setUploadedFiles}
                            setDeletedRowFileIds = {setDeletedRowFileIds}
                            setFormData = {setFormData}
                        />
                    </div>
                )}

                <Grid container spacing = {2} className = "align-items-start autocomplete">
                    {columns?.filter((column) => (column.field !== "Created_On" && column.field !== "created_on" && column.field !== "Trade_License_Document" && column.field !== "actions"))?.map((column, index) => {
                        const hasError = isFormSubmitted && validationErrors[column.field] !== undefined;
                        const errorMessage = validationErrors[column.field] || "";

                        return (
                            <Grid item xs = {4} key = {index}>
                                {column.field === "Business_Unit" ? (
                                    <VirtualizedAutocomplete
                                        isMultiple = {false}
                                        isObject = {true}
                                        filterOn = "Business Unit"
                                        options = {dropdowns["Business_Unit"] || []}
                                        selectedOptions = {formData[column.field] || null}
                                        handleSelectChange = {(filterOn, newValue) => handleChange("Business_Unit", newValue)}
                                    />
                                ) : column.field === "City" ? (
                                    <CitySelect
                                        placeHolder = "City"
                                        showFlag = {false}
                                        countryid = {country?.id || 0}
                                        stateid = {state?.id || 0}
                                        defaultValue = {{ name: formData[column.field] || "" }}
                                        onTextChange = {(event) => handleTextChange("City", event.target.value)}
                                        onChange = {(event) => handleChange("City", event)}
                                    />
                                ) : column.field === "Country" ? (
                                    <div className = "position-relative">
                                        <CountrySelect
                                            placeHolder = "Country"
                                            showFlag = {false}
                                            defaultValue = {{ name: formData[column.field] || "" }}
                                            onTextChange = {(event) => handleTextChange("Country", event.target.value)}
                                            onChange = {(event) => handleChange("Country", event)}
                                        />

                                        <IconButton aria-label = "dropdown" className = "dropdown-arrow">
                                            <ArrowDropDownIcon />
                                        </IconButton>
                                    </div>
                                ) : (title !== "Currency" && column.field === "Currency") ? (
                                    <VirtualizedAutocomplete
                                        isMultiple = {false}
                                        isObject = {true}
                                        filterOn = "Currency"
                                        options = {dropdowns["Currency"] || []}
                                        selectedOptions = {formData[column.field] || null}
                                        handleSelectChange = {(filterOn, newValue) => handleChange("Currency", newValue)}
                                    />
                                ) : column.field === "Grade" ? (
                                    <VirtualizedAutocomplete
                                        isMultiple = {false}
                                        isObject = {true}
                                        filterOn = "Product Grade"
                                        options = {dropdowns["Grade"] || []}
                                        selectedOptions = {formData[column.field] || null}
                                        handleSelectChange = {(filterOn, newValue) => handleChange("Grade", newValue)}
                                    />
                                ) : column.field === "KYC_Status" ? (
                                    <VirtualizedAutocomplete
                                        isMultiple = {false}
                                        isObject = {false}
                                        filterOn = "KYC Status"
                                        options = {dropdowns["KYC_Status"] || []}
                                        selectedOptions = {formData[column.field] || null}
                                        handleSelectChange = {(filterOn, newValue) => handleChange("KYC_Status", newValue)}
                                    />
                                ) : column.field === "State" ? (
                                    <StateSelect
                                        placeHolder = "State"
                                        showFlag = {false}
                                        countryid = {country?.id || 0}
                                        defaultValue = {{ name: formData[column.field] || "" }}
                                        onTextChange = {(event) => handleTextChange("State", event.target.value)}
                                        onChange = {(event) => handleChange("State", event)}
                                    />
                                ) : column.field === "Type_of_Packaging" ? (
                                    <VirtualizedAutocomplete
                                        isMultiple = {false}
                                        isObject = {true}
                                        filterOn = "Type of Packaging"
                                        options = {dropdowns["Type_of_Packaging"] || []}
                                        selectedOptions = {formData[column.field] || null}
                                        handleSelectChange = {(filterOn, newValue) => handleChange("Type_of_Packaging", newValue)}
                                    />
                                ) : column.field === "Unit_of_Measurement" ? (
                                    <VirtualizedAutocomplete
                                        isMultiple = {false}
                                        isObject = {true}
                                        filterOn = "UoM"
                                        options = {dropdowns["Unit_of_Measurement"] || []}
                                        selectedOptions = {formData[column.field] || null}
                                        handleSelectChange = {(filterOn, newValue) => handleChange("Unit_of_Measurement", newValue)}
                                    />
                                ) : (
                                    <div className = "text-field mt-1">
                                        <TextField
                                            label = {column.field.replaceAll("_", " ")}
                                            type = {column.type}
                                            value = {formData[column.field] || ""}
                                            error = {hasError}
                                            helperText = {hasError ? errorMessage : ""}
                                            onChange = {(event) => handleChange(column.field, event.target.value)}
                                            InputProps = {{ inputProps: { min: 0 } }}
                                            onKeyDown = {(event) => 
                                            {
                                                if (column.type === "number" && (event?.key === "-" || event?.key === "+")) 
                                                {
                                                    event.preventDefault();
                                                }
                                            }}
                                        />
                                    </div>
                                )}
                            </Grid>
                        );
                    })}
                </Grid>
            </Modal.Body>
            <Modal.Footer className = "gap-2">
                <Button variant = "outlined" size = "small" onClick = {handleClose}>
                    Cancel
                </Button>
                <Button variant = "contained" size = "small" onClick = {handleSubmit}>
                    {rowType === "Existing" ? 'Update' : 'Add'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default memo(Form);