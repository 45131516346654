import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import EntityDataTable from '../../components/EntityDataTable';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import Alert from '../../components/Alert';
import Modal from 'react-bootstrap/Modal';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import withAuth from '../../lib/withAuth';
import roles from '../../utility/roles';
import axios from 'axios';

const ApproveDocuments = ({ logOut = () => {} }) => 
{
    const { state } = useLocation();
    const { businessUnits = [] } = state || {};

    /* API VARIABLES */
    const [isLoading, setIsLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("");

    /* DATATABLE VARIABLES */
    const [columnData, setColumnData] = useState([]);
    const [columnDataTypes, setColumnDataTypes] = useState({});
    const [columnWidths, setColumnWidths] = useState({});
    const [rows, setRows] = useState([]);
    const [toggleDisplayData, setToggleDisplayData] = useState(false);

    /* DATATABLE ROW DATA VARIABLES */
    const [rowData, setRowData] = useState({});

    /* TAB VARIABLES */
    const [selectedTab, setSelectedTab] = useState(0);

    /* MODAL VARIABLES */
    const [showDisableModal, setShowDisableModal] = useState(false);

    useEffect(() =>
    {
        const fetchData = async () => 
        {
            setIsLoading(true);
            setShowAlert(false);
            setAlertMessage("");
            setAlertType("");
            setRowData({});
    
            await axios({
                method: "post",
                url: "/GetDocumentData",
                data: {
                    selected_deal_type: selectedTab ? businessUnits[selectedTab]?.id : businessUnits[0]?.id
                }
            })
            .then((response) => 
            {
                setIsLoading(false);
                const { status, data } = response;
        
                if (status === 200) 
                {
                    const documentData = data?.document_data || {};
                    const { rows: rowData = [], columns = [], data_types: dataTypes = {}, max_column_lengths: widths = {} } = documentData;
                    const columnData = columns.filter(column => !["id", "isApproved", "deal_pfi_id", "tranche_id", "coo_id", "revision_number"].includes(column)) || [];
        
                    setColumnData(columnData);
                    setColumnDataTypes(dataTypes);
                    setColumnWidths(widths);
                    setRows(rowData);
                }
                else
                {
                    setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
                    setAlertType("error");
                    setShowAlert(true);
                }
            })
            .catch((error) => 
            {
                console.log("Get Document Data Api: ", error);
                setIsLoading(false);
                
                if (error?.response?.status === 403) 
                {
                    logOut();
                }
                else
                {
                    setAlertMessage(
                    error?.response?.status === 429
                        ? "Request limit exceeded. Please try again later."
                        : "An error occurred while processing your request. Please try again later or contact the site administrator."
                    );
                    setAlertType("error");
                    setShowAlert(true);
                }
            });
        }
        
        if (businessUnits?.length > 0)
        {
            fetchData();
        }

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [businessUnits, selectedTab, toggleDisplayData]);

    const handleTabChange = (tab) =>
    {
        setSelectedTab(tab);
    }

    const handleViewDocument = async (rowData) =>
    {
        setIsLoading(true);
        setShowAlert(false);
        setAlertMessage("");
        setAlertType("");

        const documentRowData = {
            Deal_PFI_Id: rowData?.deal_pfi_id,
            Tranche_Id:  rowData?.tranche_id,
            Document_Type: rowData?.["Document Type"],
            COO_ID: rowData?.coo_id,
            Revision_Number: rowData?.revision_number
        };
    
        await axios({
            method: "post",
            url: "/ViewDocument",
            data: documentRowData
        })
        .then((response) => 
        {
            setIsLoading(false);
            const { status, data } = response;
        
            if (status === 200) 
            {
                window.open(data, '_blank', 'noopener,noreferrer');
            }
            else
            {
                setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
                setAlertType("error");
                setShowAlert(true);
            }
        })
        .catch((error) => 
        {
            console.log("View Document Api: ", error);
            setIsLoading(false);
        
            if (error?.response?.status === 403) 
            {
                logOut();
            }
            else
            {
                setAlertMessage(
                error?.response?.status === 429
                    ? "Request limit exceeded. Please try again later."
                    : "An error occurred while processing your request. Please try again later or contact the site administrator."
                );
                setAlertType("error");
                setShowAlert(true);
            }
        });
    }

    const handleRecordStatusChangeWrapper = (rowData) =>
    {
        setRowData(rowData);

        if (rowData?.isApproved)
        {
            setShowDisableModal(true);
        }
        else
        {
            handleRecordStatusChange(rowData);
        }
    }
    
    const handleRecordStatusChange = useCallback(async (rowData) => 
    {
        setShowDisableModal(false);
        setIsLoading(true);
        setShowAlert(false);
        setAlertMessage("");
        setAlertType("");
    
        const documentRowData = {
            Deal_PFI_Id: rowData?.deal_pfi_id,
            Tranche_Id:  rowData?.tranche_id,
            Document_Type: rowData?.["Document Type"],
            COO_ID: rowData?.coo_id,
            Revision_Number: rowData?.revision_number,
            Document_Status: rowData?.isApproved ? 0 : 1
        };

        await axios({
            method: "post",
            url: "/SetDocumentStatus",
            data: documentRowData
        })
        .then((response) => 
        {
            setIsLoading(false);
            const { status } = response;
        
            if (status === 200) 
            {
                setToggleDisplayData(!toggleDisplayData);
            }
            else
            {
                setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
                setAlertType("error");
                setShowAlert(true);
            }
        })
        .catch((error) => 
        {
            console.log("Document Status Api: ", error);
            setIsLoading(false);
            
            if (error?.response?.status === 403) 
            {
                logOut();
            }
            else
            {
                setAlertMessage(
                error?.response?.status === 429
                    ? "Request limit exceeded. Please try again later."
                    : "An error occurred while processing your request. Please try again later or contact the site administrator."
                );
                setAlertType("error");
                setShowAlert(true);
            }
        });
    
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [rowData]);
    
    const handleCloseModal = () =>
    {
        setShowDisableModal(false);
    }
      
    return (
        <div className = "content_container d-flex flex-column container approve-document">
            <Alert
                show = {showAlert}
                message = {alertMessage}
                type = {alertType}
                setShow = {setShowAlert}
            />

            <Typography variant = "h5" gutterBottom className = "page_heading m-0">
                Approve Documents
            </Typography>

            <div className = {`${businessUnits?.length > 0 ? 'show-tabs' : 'hide-tabs'}`}>
                {businessUnits?.length > 0 && (
                    <Box display = "flex" justifyContent = "center" width = "100%">
                        <Tabs
                            value = {selectedTab}
                            onChange = {(event, newValue) => handleTabChange(newValue)}
                            variant = "scrollable"
                            scrollButtons = "auto"
                            sx = {{ 
                                minHeight: '38px !important',
                                marginBottom: '0.5em',
                                backgroundColor: '#eef2ff',
                                '& .MuiTab-root': {
                                    minHeight: '38px !important'
                                }
                            }}
                        >
                            {businessUnits?.map((businessUnit) => (
                                <Tab key = {businessUnit.id} label = {businessUnit.value === "SBLC" ? "Polymers - SBLC" : businessUnit.value} />
                            ))}
                        </Tabs>
                    </Box>
                )}

                <Backdrop
                    sx = {{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open = {isLoading}
                />
                
                <EntityDataTable
                    title = "Approve Documents"
                    isLoading = {isLoading}
                    columnData = {columnData}
                    columnDataTypes = {columnDataTypes}
                    columnWidths = {columnWidths}
                    rows = {rows}
                    files = {[]}
                    setIsLoading = {setIsLoading}
                    setShowAlert = {setShowAlert}
                    setAlertMessage = {setAlertMessage}
                    setAlertType = {setAlertType}
                    handleViewDocument = {handleViewDocument}
                    handleRecordStatusChange = {handleRecordStatusChangeWrapper}
                />
            </div>
        
            <Modal show = {showDisableModal} onHide = {handleCloseModal} centered = {true}>
                <Modal.Header closeButton = {true}>
                    <Modal.Title>Are you sure?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>Disapproving the document will remove it from the list of approved documents.</span>
                </Modal.Body>
                <Modal.Footer className = "gap-2">
                    <Button variant = "outlined" size = "small" onClick = {handleCloseModal}>
                        Cancel
                    </Button>
                    <Button variant = "contained" size = "small" onClick = {() => handleRecordStatusChange(rowData)}>
                        Disapprove
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default withAuth(ApproveDocuments)([roles.Administrator]);