import { useState } from 'react';
import { Navigate, useNavigate, Link, useLocation } from 'react-router-dom';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Logo from '../../images/logo.png';
import axios from 'axios';
import '../../styles/authentication.css';

const ChangePassword = ({ currentUser }) => 
{
    const [isLoading, setIsLoading] = useState(false);
    const [alertError, setAlertError] = useState(null);
    const { state } = useLocation();
    const location = useLocation();
    const navigate = useNavigate();

    /* PASSWORD VARIABLES */
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState(null);

    const handleToggleShowPassword = () => 
    {
        setShowPassword(previousShowPassword => !previousShowPassword);
    }
    
    const handlePasswordChange = (event) =>
    {
        setPassword(event.target.value);
    }

    const handleConfirmPasswordChange = (event) => 
    {
        if (typeof event.target.value !== "undefined" && event.target.value !== "") 
        {
            if (password !== event.target.value) 
            {
                event.target.setCustomValidity("Passwords do not match.");
            }
            else
            {
                event.target.setCustomValidity("");
            }

            setConfirmPassword(event.target.value);
        }
    }

    const handleSubmit = async (event) => 
    {
        event.preventDefault();
            
        setIsLoading(true);
        setAlertError(null);

        await axios({
            method: "post",
            url: "/ChangePassword",
            data: { token: location?.pathname?.split("/")[4], password: confirmPassword }
        })
        .then((response) =>
        {
            setIsLoading(false);
            const { status, data } = response;

            if (status === 200)
            {
                navigate("/login", { replace: false });
            }
            else
            {
                setAlertError(data.message);
            }
        })
        .catch((error) =>
        {
            console.log("Change Password Api: ", error);
            setIsLoading(false);
            setAlertError("An error occurred while processing your request. Please try again later or contact the site administrator.");
        });
    }

    return (
        <>
            {currentUser?.email ? (
                <Navigate to = {state?.path || "/"} replace = {true} />
            ) : (
                <div className = "loginContainer">      
                    <div className = "login">
                        <img src = {Logo} alt = "Engro" />        
                        <h2 className = "mt-0 text-white">Change Password</h2>
                        
                        {alertError && <Alert variant = "danger">{alertError}</Alert>}
                        
                        <form onSubmit = {handleSubmit} className = "d-flex flex-column gap-3 mt-2">
                            <div className = "form-group password-container">
                                <input
                                    type = {showPassword ? "text" : "password"}
                                    id = "password"
                                    name = "password"
                                    className = "form-control"
                                    placeholder = "Enter Password"
                                    onChange = {handlePasswordChange}
                                    required
                                />

                                {showPassword ? (
                                    <AiOutlineEyeInvisible className = "show-hide-password" title = "Hide Password" onClick = {handleToggleShowPassword} />
                                ) : (
                                    <AiOutlineEye className = "show-hide-password" title = "Show Password" onClick = {handleToggleShowPassword} />
                                )}
                            </div>

                            <div className = "form-group password-container">
                                <input
                                    type = {showPassword ? "text" : "password"}
                                    id = "password"
                                    name = "confirm-password"
                                    className = "form-control"
                                    placeholder = "Enter Confirmed Password"
                                    onChange = {handleConfirmPasswordChange}
                                    required
                                />

                                {showPassword ? (
                                    <AiOutlineEyeInvisible className = "show-hide-password" title = "Hide Password" onClick = {handleToggleShowPassword} />
                                ) : (
                                    <AiOutlineEye className = "show-hide-password" title = "Show Password" onClick = {handleToggleShowPassword} />
                                )}
                            </div>               
            
                            <div className = "d-flex justify-content-between align-items-center mt-3">
                                <div className = "d-flex justify-content-between align-items-center reset-password m-0">
                                    <span></span>
                                    <Link to = "/login" className = "login-link">Back to Login</Link>
                                </div>
                
                                <Button
                                    type = "submit"
                                    variant = "primary"
                                    disabled = {isLoading}
                                    className = "login-button log-in custom_btn"
                                >
                                    {isLoading ? (
                                        <div className = "spinner-border text-info" role = "status" />
                                    ) : (
                                        'Save Password'
                                    )}
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </>
    );
}

export default ChangePassword;