import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import DealReport from './DealReport';
import FinanceReport from './FinanceReport';
import MorningReport from './MorningReport';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '../../components/Alert';
import withAuth from '../../lib/withAuth';
import roles from '../../utility/roles';

const Reports = ({ logOut = () => {} }) => 
{
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const { state } = useLocation();
  const { businessUnits = [], selectedReport } = state || {};

  return (
    <div className = "content_container d-flex flex-column container reports gap-2">
      <Alert
        show = {showAlert}
        message = {alertMessage}
        type = {alertType}
        setShow = {setShowAlert}
      />

      <Typography variant = "h5" gutterBottom className = "page_heading mb-2">
        {selectedReport} {selectedReport === "Morning Report" ? "(Multi-Filters)" : ""}
      </Typography>

      {selectedReport === "Deal Report" ? (
        <DealReport 
          isLoading = {isLoading}
          businessUnits = {businessUnits}
          setIsLoading = {setIsLoading}
          setShowAlert = {setShowAlert}
          setAlertMessage = {setAlertMessage}
          setAlertType = {setAlertType}
          logOut = {logOut}
        />
      ) : selectedReport === "Finance Report" ? (
        <FinanceReport 
          isLoading = {isLoading}
          businessUnits = {businessUnits}
          setIsLoading = {setIsLoading}
          setShowAlert = {setShowAlert}
          setAlertMessage = {setAlertMessage}
          setAlertType = {setAlertType}
          logOut = {logOut}
        />
      ) : selectedReport === "Morning Report" && (
        <MorningReport 
          isLoading = {isLoading}
          businessUnits = {businessUnits}
          setIsLoading = {setIsLoading}
          setShowAlert = {setShowAlert}
          setAlertMessage = {setAlertMessage}
          setAlertType = {setAlertType}
          logOut = {logOut}
        />
      )}
      
      <div>
        <Backdrop
          sx = {{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open = {isLoading}
        >
          <CircularProgress color = "inherit" />
        </Backdrop>
      </div>
    </div>
  );
}

export default withAuth(Reports)([roles.Administrator, roles.User]);