import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import Button from 'react-bootstrap/Button';
import Alert from '../../components/Alert';
import withAuth from '../../lib/withAuth';
import roles from '../../utility/roles';
import axios from 'axios';

const EditProfile = ({ logOut = () => {} }) => 
{
    const [isLoading, setIsLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("");

    /* AUTHENTICATION VARIABLES */
    const currentUser = useSelector(state => state.user);

    /* FORM VARIABLES */
    const [formData, setFormData] = useState({});
    const formRef = useRef(null);

    /* PASSWORD VARIABLES */
    const [showPassword, setShowPassword] = useState(false);
    const confirmPasswordInputRef = useRef(null);

    useEffect(() =>
    {
        const { name, email, role } = currentUser || {};
        const fullName = name.split(" ");
        const userData = {
            firstName: fullName[0],
            lastName: fullName?.[1],
            email: email,
            role: role
        }

        setFormData(userData);
    }, [currentUser]);

    const handleToggleShowPassword = () => 
    {
        setShowPassword(previousShowPassword => !previousShowPassword); 
    }

    const handleChange = (event) => 
    {
        const { name, value } = event.target;
        const userData = { ...formData };

        if (typeof value !== "undefined" && value !== "") 
        {
            if (name === "firstName" || name === "lastName") 
            {
                const namePattern = /^[a-zA-Z\s'-]+$/;

                if (!namePattern.test(value)) 
                {
                    event.target.setCustomValidity("Please enter a valid name.");
                } 
                else 
                {
                    event.target.setCustomValidity("");
                    userData[name] = value;
                }
            } 
            else if (name === "email") 
            {
                const emailPattern = new RegExp(
                    /^(('[\w\s-]+')|([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
                );

                if (!emailPattern.test(value)) 
                {
                    event.target.setCustomValidity("Please enter a valid email address.");
                } 
                else 
                {
                    event.target.setCustomValidity("");
                    userData[name] = value;
                }
            } 
            else if (name === "newPassword") 
            {
                if (userData?.confirmNewPassword !== value) 
                {
                    confirmPasswordInputRef?.current?.setCustomValidity("Password and confirm password values do not match!");
                } 
                else 
                {
                    confirmPasswordInputRef?.current?.setCustomValidity("");
                }

                userData[name] = value;
            } 
            else if (name === "confirmNewPassword") 
            {
                if (userData?.newPassword !== value) 
                {
                    event.target.setCustomValidity("Password and confirm password values do not match!");
                } 
                else 
                {
                    event.target.setCustomValidity("");
                }

                userData[name] = value;
            } 
            else 
            {
                userData[name] = value;
            }
        }

        setFormData(userData);
    }

    const handleSubmit = async (event) => 
    {
        event.preventDefault();

        setIsLoading(true);
        setShowAlert(false);
        setAlertMessage("");
        setAlertType("");

        await axios({
            method: "post",
            url: "/UpdateUser",
            data: {
                user_id: currentUser?.user_id,
                ...formData
            }
        })
        .then((response) => 
        {
            setIsLoading(false);
            const { status, data } = response;

            if (status === 200) 
            {
                setAlertMessage("Profile updated successfully. Please log out and log in again to apply the changes.");
                setAlertType("success");
                setShowAlert(true);
            } 
            else if (status === 202) 
            {
                setAlertMessage(data?.data || "");
                setAlertType("error");
                setShowAlert(true);
            }
        })
        .catch((error) => 
        {
            console.log("Edit User Profile Api: ", error);
            setIsLoading(false);

            if (error?.response?.status === 403) 
            {
                logOut();
            }
            else
            {
                setAlertMessage(
                    error?.response?.status === 429
                        ? "Request limit exceeded. Please try again later."
                        : "An error occurred while processing your request. Please try again later or contact the site administrator."
                );
                setAlertType("error");
                setShowAlert(true);
            }
        });
    }

    return (
        <div className = "content_container d-flex flex-column mt-0 user">
            <Alert
                show = {showAlert}
                message = {alertMessage}
                type = {alertType}
                setShow = {setShowAlert}
            />

            <div className = "py-4">
                <form autoComplete = "off" ref = {formRef} className = "add-user-form" onSubmit = {handleSubmit} >
                    <div className = "form-group">
                        <label htmlFor = "firstName">First Name</label>

                        <input
                            type = "text"
                            className = "form-control"
                            name = "firstName"
                            placeholder = "Enter First Name"
                            value = {formData?.firstName || ""}
                            onChange = {handleChange}
                            required
                        />
                    </div>

                    <div className = "form-group">
                        <label htmlFor = "lastName">Last Name</label>

                        <input
                            type = "text"
                            className = "form-control"
                            name = "lastName"
                            placeholder = "Enter Last Name"
                            value = {formData?.lastName || ""}
                            onChange = {handleChange}
                            required
                        />
                    </div>

                    <div className = "form-group">
                        <label htmlFor = "email">Email Address</label>

                        <input
                            type = "email"
                            className = "form-control"
                            name = "email"
                            placeholder = "Enter Email Address"
                            value = {formData?.email || ""}
                            onChange = {handleChange}
                            required
                        />
                    </div>

                    <div className = "form-group">
                        <label htmlFor = "role">Role</label>

                        <input
                            type = "text"
                            className = "form-control"
                            name = "role"
                            value = {formData?.role || ""}
                            disabled
                        />
                    </div>

                    <hr />

                    <div className = "form-group">
                        <label htmlFor = "oldPassword">Old Password</label>

                        <div className = "password-container">
                            <input
                                type = {showPassword ? "text" : "password"}
                                id = "oldPassword"
                                name = "oldPassword"
                                className = "form-control"
                                placeholder = "Enter Old Password"
                                onChange = {handleChange}
                                required
                            />

                            {showPassword ? (
                                <AiOutlineEyeInvisible
                                    className = "show-hide-password"
                                    title = "Hide Password"
                                    onClick = {handleToggleShowPassword}
                                />
                            ) : (
                                <AiOutlineEye
                                    className = "show-hide-password"
                                    title = "Show Password"
                                    onClick = {handleToggleShowPassword}
                                />
                            )}
                        </div>
                    </div>

                    <div className = "form-group">
                        <label htmlFor = "newPassword">New Password</label>

                        <div className = "password-container">
                            <input
                                type = {showPassword ? "text" : "password"}
                                id = "newPassword"
                                name = "newPassword"
                                className = "form-control"
                                placeholder = "Enter New Password"
                                onChange = {handleChange}
                                required
                            />

                            {showPassword ? (
                                <AiOutlineEyeInvisible
                                    className = "show-hide-password"
                                    title = "Hide Password"
                                    onClick = {handleToggleShowPassword}
                                />
                            ) : (
                                <AiOutlineEye
                                    className = "show-hide-password"
                                    title = "Show Password"
                                    onClick = {handleToggleShowPassword}
                                />
                            )}
                        </div>
                    </div>

                    <div className = "form-group">
                        <label htmlFor = "confirmNewPassword">Confirm New Password</label>

                        <div className = "password-container">
                            <input
                                ref = {confirmPasswordInputRef}
                                type = {showPassword ? "text" : "password"}
                                id = "confirmNewPassword"
                                name = "confirmNewPassword"
                                className = "form-control"
                                placeholder = "Enter Confirmed New Password"
                                onChange = {handleChange}
                                required
                            />

                            {showPassword ? (
                                <AiOutlineEyeInvisible
                                    className = "show-hide-password"
                                    title = "Hide Password"
                                    onClick = {handleToggleShowPassword}
                                />
                            ) : (
                                <AiOutlineEye
                                    className = "show-hide-password"
                                    title = "Show Password"
                                    onClick = {handleToggleShowPassword}
                                />
                            )}
                        </div>
                    </div>

                    <div className = "text-center pt-2">
                        <Button
                            className = "btn custom_btn btn-primary m-auto"
                            variant = "primary"
                            type = "submit"
                            disabled = {isLoading}
                        >
                            {isLoading ? (
                                <div className = "spinner-border text-info" role = "status" />
                            ) : (
                                "Update User"
                            )}
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default withAuth(EditProfile)([roles.Administrator, roles.User]);